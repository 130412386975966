import React from 'react';
import { FormattedMessage } from 'react-intl';
import { usePurpose } from '../../../../../context/purpose';
import { getEmailFirstRoutes } from '../../../../../pages/email-first/emailFirstRouteNames';
import { EmailFirstForm } from '../../emailFirstForm';
import {
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EmailFirstDateOfBirthField,
} from '../../inputs';
import { useEmailFirstForm } from '../../useEmailFirstForm';
import { StyledTermsAndConditions } from '../emailFirstDetailsForm.style';
import { useStateContext } from '../../../../../modules/stateContext';
import {
    EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME,
    EmailFirstMarketingOptInField,
} from '../../inputs/marketing';
import { StyledEmailFirstMarketingSection } from './emailFirstDetailsFormExUk.style';

const activeFields = [
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME,
];

export const EmailFirstDetailsFormExUk = () => {
    const {
        location: { country },
        isFederated,
        isSingleEntryAuth,
        featureToggles,
    } = useStateContext();

    const { isSubscription: isPurposeSubscription } = usePurpose();

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );
    const {
        fieldErrors,
        formError,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields: featureToggles.hideDateOfBirth
            ? [EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME]
            : activeFields,
        countryCode: country,
        prevRoute: emailFirstPaths.password.path,
        submitToRegister: true,
    });

    return (
        <EmailFirstForm
            activeFields={activeFields}
            heading={
                featureToggles.hideDateOfBirth ? (
                    <FormattedMessage id="emailFirst.detailsForm.marketing.titleExUk" />
                ) : (
                    <FormattedMessage id="emailFirst.detailsForm.title" />
                )
            }
            formError={formError}
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first-details"
            submitButtonLabel={
                isPurposeSubscription
                    ? 'subscription.checkoutButton.label'
                    : 'register.button.submit.value'
            }
        >
            {featureToggles.hideDateOfBirth === false && (
                <EmailFirstDateOfBirthField />
            )}
            <StyledEmailFirstMarketingSection>
                {featureToggles.hideDateOfBirth === false && (
                    <p>
                        <FormattedMessage id="emailFirst.detailsForm.marketing.titleExUk" />
                    </p>
                )}
                <EmailFirstMarketingOptInField
                    error={fieldErrors[EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME]}
                />
            </StyledEmailFirstMarketingSection>
            <StyledTermsAndConditions
                isInternational
                linkTarget="_blank"
                messageId="register.message.acceptTerms"
            />
        </EmailFirstForm>
    );
};
