import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { EmailFirstForm } from '../emailFirstForm';
import {
    EMAIL_FIRST_EMAIL_FORM_INPUT_NAME,
    EmailFirstEmailFormInput,
} from '../inputs';
import { useEmailFirstForm } from '../useEmailFirstForm';
import { useSessionStorage } from '../../../../hooks/useSessionStorage';
import { useShowExUkContent } from '../../../../hooks/useShowExUkContent';
import { getEmailFirstRoutes } from '../../../../pages/email-first/emailFirstRouteNames';
import { useStateContext } from '../../../../modules/stateContext';
import { AgeDeclarationTitle } from './emailFirstEmailForm.style';
import { useConsentAge } from '../../../../context/consentAge';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import { REGISTRATION_START_EVENT } from '../../../../../shared/data/customEvents/registration';
import { useEmailFirstEmailFormCheckUniqueness } from './useEmailFirstEmailFormCheckUniqueness';
import { useExperiment } from '../../../../experiments/useExperiment';
import { EMAIL_VERIFICATION_OPTIMIZELY_EXPERIMENT } from '../../../../../shared/data/mvtExperiments';
import { usePurpose } from '../../../../context/purpose';

const UNEXPECTED_ERROR = 'message.gracefulDeg.technicalProblems';

const activeFields = [EMAIL_FIRST_EMAIL_FORM_INPUT_NAME];

export const ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY =
    'oneTimePersistentUserIdentifierKey';

export const EmailFirstEmailForm = () => {
    const [formErrors, setFormErrors] = useState();

    const history = useHistory();

    const userContext = useStateContext();

    const {
        featureToggles: {
            consentAgeByCountry,
            accountEmailVerification,
            hideDateOfBirth,
        },
        isFederated,
        isSingleEntryAuth,
        location: { isUk },
    } = userContext;

    const isEmailVerificationExperimentEnabled =
        useExperiment(EMAIL_VERIFICATION_OPTIMIZELY_EXPERIMENT.NAME) === 'on';

    const { isSubscription: isPurposeSubscription } = usePurpose();

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    const { sessionStorage } = useSessionStorage();

    const isEmailVerificationEnabled =
        isEmailVerificationExperimentEnabled &&
        accountEmailVerification &&
        isUk;

    const checkUniqueness = useEmailFirstEmailFormCheckUniqueness();

    const preSubmit = useCallback(async ({ fieldValues }) => {
        setFormErrors(undefined);

        try {
            const { dns, error, exists } = await checkUniqueness(
                fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
            );

            if (exists) {
                if (isSingleEntryAuth) {
                    history.push(
                        `${emailFirstPaths.login.path}${renderQueryString.call({
                            ...userContext,
                            action: 'sign-in',
                        })}`
                    );

                    return false;
                }

                if (typeof error === 'undefined') {
                    throw new TypeError(UNEXPECTED_ERROR);
                }

                if (Array.isArray(error)) {
                    setFormErrors(error);
                } else {
                    const errorId = error?.email?.id;

                    if (typeof errorId === 'undefined') {
                        throw new TypeError(error);
                    }

                    if (errorId === 'emailFirst.email.duplicate') {
                        sessionStorage?.setItem(
                            ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY,
                            fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
                        );
                    }

                    const duplicateErrorMessage = isFederated
                        ? error?.federatedEmail
                        : error?.email;

                    setFormErrors(duplicateErrorMessage);
                }

                return false;
            }

            if (dns === false) {
                setFormErrors(error);

                return false;
            }
        } catch (error) {
            setFormErrors(UNEXPECTED_ERROR);

            return false;
        }

        return true;
    }, []);

    const {
        fieldValues,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        nextRoute: isEmailVerificationEnabled
            ? emailFirstPaths.verification.path
            : emailFirstPaths.password.path,
        preSubmit,
        customEventDataOnSubmit: isSingleEntryAuth
            ? REGISTRATION_START_EVENT
            : undefined,
    });

    const showExUkContent = useShowExUkContent();

    const headingId = useMemo(() => {
        if (isSingleEntryAuth) {
            if (isPurposeSubscription) {
                return 'emailFirst.subscription.identifier.title';
            }

            return showExUkContent
                ? 'emailFirst.identifier.titleExUk'
                : 'emailFirst.identifier.title';
        }

        if (showExUkContent) {
            return 'register.title.introductionExUk';
        }

        return 'emailFirst.emailForm.title';
    }, [showExUkContent, isSingleEntryAuth, isPurposeSubscription]);

    const { consentAge } = useConsentAge();

    const showAgeConsentTitle = useMemo(() => {
        if (!showExUkContent) {
            return false;
        }

        if (isPurposeSubscription) {
            return false;
        }

        if (hideDateOfBirth && isSingleEntryAuth) {
            return false;
        }

        return true;
    }, [
        showExUkContent,
        isPurposeSubscription,
        hideDateOfBirth,
        isSingleEntryAuth,
    ]);

    return (
        <EmailFirstForm
            activeFields={activeFields}
            formError={formErrors}
            heading={<FormattedMessage id={headingId} />}
            hideBackButton
            name="email-first-email-form"
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first"
            submitButtonLabel="button.continue.value"
            withHelpLink
            withSignInCta={!isSingleEntryAuth}
        >
            {showAgeConsentTitle && (
                <AgeDeclarationTitle>
                    <FormattedMessage
                        id={
                            consentAgeByCountry
                                ? 'emailFirst.emailForm.ageDeclarationConsentAge.title'
                                : 'emailFirst.emailForm.ageDeclaration.title'
                        }
                        values={{
                            consentAge,
                        }}
                    />
                </AgeDeclarationTitle>
            )}
            <EmailFirstEmailFormInput
                defaultValue={
                    fieldValues &&
                    fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
                }
            />
        </EmailFirstForm>
    );
};
