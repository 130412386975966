import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    margin-bottom: ${({ noBottomMargin }) =>
        noBottomMargin ? '0' : 'var(--spacing-7)'};
`;
