// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore id-components does not have type declarations. Replace this component with id-components v2 when ready.
import { Radio } from '@bbc-account/id-components';
import { useFieldState } from '@bbc-account/id-formaxe';
import { marketingOptIn as marketingOptInValidator } from '@bbc-account/id-validators';
import { FieldError } from '@bbc-account/id-formaxe/dist/types';
import React, { ChangeEvent, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Track from '../../../../shared/analytics/track';
import FormError from '../../../../shared/formError';
import { useEmailFirstInputFieldEvents } from '../text/useEmailFirstInputFieldEvents';
import { StyledEmailFirstMarketingOptInField } from './emailFirstMarketingOptInField.style';
import { useStateContext } from '../../../../../modules/stateContext';

export const EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME = 'marketingOptIn';

const marketingOnTranslationId = 'emailFirst.detailsForm.marketing.yes';

const marketingOffTranslationId = 'emailFirst.detailsForm.marketing.no';

type EmailFirstMarketingOptInFieldProps = {
    error?: FieldError;
};

export function EmailFirstMarketingOptInField({
    error,
}: EmailFirstMarketingOptInFieldProps) {
    const { featureToggles } = useStateContext();
    const { formatMessage } = useIntl();

    const { eventHandlers } = useFieldState(
        EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME
    );

    const { validate } = useEmailFirstInputFieldEvents({
        id: EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME,
        onBlur: undefined,
        onChange: undefined,
        validator: marketingOptInValidator,
    });

    const radioFields = [
        {
            'aria-label': formatMessage({
                id: marketingOnTranslationId,
            }),
            id: 'optIn',
            label: <FormattedMessage id={marketingOnTranslationId} />,
            value: 'on',
        },
        {
            'aria-label': formatMessage({
                id: marketingOffTranslationId,
            }),
            id: 'optOut',
            label: <FormattedMessage id={marketingOffTranslationId} />,
            value: 'off',
        },
    ];

    const handleRadioChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            validate(event.target.value);
            eventHandlers.onChange(event);
        },
        [eventHandlers.onChange]
    );

    return (
        <div>
            <fieldset>
                <StyledEmailFirstMarketingOptInField
                    justifyCenter={featureToggles.hideDateOfBirth}
                >
                    {radioFields.map(({ id, ...props }) => (
                        <Track key={id} ignoreViews name={`marketing-${id}`}>
                            <Radio
                                id={id}
                                isInvalid={!!error}
                                name={EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME}
                                required
                                {...props}
                                {...eventHandlers}
                                onChange={handleRadioChange}
                            />
                        </Track>
                    ))}
                </StyledEmailFirstMarketingOptInField>
            </fieldset>
            {error && (
                <div className="u-margin-top-quad">
                    <FormError
                        error={error}
                        name={EMAIL_FIRST_MARKETING_OPT_IN_INPUT_NAME}
                        migrateToIdComponents
                    />
                </div>
            )}
        </div>
    );
}
