import React from 'react';
import { ButtonInline } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Container } from './editUserIdentifier.style';
import Track from '../../shared/analytics/track';

export const EditUserIdentifier = ({
    onClick,
    userIdentifier,
    noBottomMargin,
}) => {
    return (
        <Container noBottomMargin={noBottomMargin}>
            {userIdentifier}
            <Track ignoreViews name="edit-email" type="link">
                <ButtonInline onClick={onClick}>
                    <FormattedMessage id="emailFirst.editEmail.title" />
                </ButtonInline>
            </Track>
        </Container>
    );
};

EditUserIdentifier.propTypes = {
    userIdentifier: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    noBottomMargin: PropTypes.bool.isRequired,
};
